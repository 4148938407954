import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import home_icon from "../../images/home_icon.svg";
import HeaderNav from "../HeaderNav";
import Footer from "../Footer";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { AppContext } from "../../App";
import ReportsBody from "./ReportsBody";
import { getStudentReports, transformObj } from "../../services";
import { useNavigate } from "react-router-dom";

const ReportsParent = (props) => {
  const appContext = React.useContext(AppContext);
  const appData = appContext.data;
  const loc = useParams();
  const [studentReport, setStudentReport] = useState({});
  const [selectedSubject, setSelectedSubject] = useState({});
  const [header, setHeader] = useState("");
  const [guideLink, setGuideLink] = useState("");
  const setShowErrorModal = useState(null);
  const navigate = useNavigate();
  const reportTypeOrder = appContext.data.staticText.parentportal_reporttype_order;
  const contentAreaOrder = appContext.data.staticText.parentportal_contentarea_order;
  const [sortedReports, setSortedReports] = useState({});
  const onSubjectSelect = (sub) => {
    setSelectedSubject(sub);
    let student = appContext.data.students.find(o => o.studentid === parseInt(loc.id));
    appContext.dispatch({
      type: "ADD_LOG",
      data: {
        type: "ACCESS_REPORTS",
        logData: `Parent ${appContext.data.parent.email} accessed Student ${loc.id}’s ${sub.reporttype} ${sub.subjectname} report page. `,
        districtid: student.districtid,
        schoolyear: student.schoolyear,
      },
    });
  };
  useEffect(() => {
    const allReports = appContext.data.studentReports;
    const report = allReports.find((rep) => loc.id === rep.id);
    setStudentReport(report ? report : {});

    const firstSubject = report?.reporttype[0]?.gridReportDetails[0];

    onSubjectSelect(firstSubject ? firstSubject : {});
    setHeader(report?.reporttype[0]?.header);
    // Background: database returns result based on the most recent report generated for the student so depending on the time of the year, it could be KAP Summative report or KAP Interim report or something else.
    // The reason we are setting the guide link before sorting the report types is to avoid the report type and guide link to become out of sync. By moving setGuideLink(report?.reporttype[0]?.guideLinkDescriptions) above the sorting function,
    // we ensure that the guide link is set based on the initial report returned from the backend (which could be KAP Interim),
    // and not overwritten by the sorting logic that prioritizes KAP Summative in the order.
   
    // For example: The first report in the array (after sorting by reportTypeOrder) could be a KAP Interim report
    // and the guide link could be tied to KAP Summative.
    setGuideLink(report?.reporttype[0]?.guideLinkDescriptions);
    setSortedReports(allReports[0].reporttype.sort((a, b) => {
      return reportTypeOrder.indexOf(a.testType) - reportTypeOrder.indexOf(b.testType)
    }));
  }, []);
  const getReports = (e, studentId) => {
    e.preventDefault();
    getStudentReports(studentId)
      .then((res) => {
        const transformedData = transformObj(res.data.message);
        appContext.dispatch({
          type: "ADD_STUDENTREPORTS",
          data: transformedData,
        });
        appContext.dispatch({
          type: "ADD_LOG",
          data: {
            type: "ACCESS_STUDENT_DETAILS",
            logData: `Parent ${appData.parent.email} accessed Student ${transformedData.name}’s report details`,
            districtid: transformedData.districtid,
            schoolyear: transformedData.schoolyear,
          },
        });
        navigate(`/studentreports/${studentId}`);
      })
      .then(() => window.location.reload())
      .catch((err) => setShowErrorModal(err.message));
  };
  const reportTypeSort = (a, b) => {
    return reportTypeOrder.indexOf(a.testType) - reportTypeOrder.indexOf(b.testType)
  }
  const contentAreaSort = (a, b) => {
    return contentAreaOrder.indexOf(a.contentarea) - contentAreaOrder.indexOf(b.contentarea)
  }
  const selectedReport = studentReport.reporttype
    ? studentReport.reporttype.find((typ) => { return typ.testType === selectedSubject.reporttype; }) : null;

  return (

    <div className="container">
      <HeaderNav />
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/home" style={{ padding: "5px" }}>
              <img width="18" src={home_icon} alt="Go Home" />
            </Nav.Link>
            <NavDropdown
              title={selectedSubject.reporttype}
              onSelect={(i) => {
                let typ = studentReport.reporttype.sort(reportTypeSort)[i];
                setHeader(typ.header);
                setGuideLink(typ.guideLinkDescriptions);
                onSubjectSelect(typ.gridReportDetails[0]);
              }}>
              {studentReport.reporttype
                ? studentReport.reporttype.sort(reportTypeSort).map((typ, i) =>
                  <NavDropdown.Item
                    key={i}
                    eventKey={i}
                  >
                    {typ.testType}
                  </NavDropdown.Item>
                ) : ""}
            </NavDropdown>
            <NavDropdown
              title={selectedSubject.subjectname}
              onSelect={(i) => {
                setHeader(selectedReport.header);
                setGuideLink(selectedReport.guideLinkDescriptions);
                onSubjectSelect(selectedReport.gridReportDetails.sort(contentAreaSort)[i]);
              }}>
              {selectedReport?.gridReportDetails.sort(contentAreaSort).map((sub, i) =>
                <NavDropdown.Item
                  key={i}
                  eventKey={i}
                >
                  {sub.subjectname}
                </NavDropdown.Item>
              )}
            </NavDropdown>
            <Nav.Link href="/contact">Help</Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            <NavDropdown
              title={<span><b>Student:</b> {studentReport.name}</span>}
            >
              {appData.students.sort((a, b) => {
                let fa = a.studentname.toLowerCase(),
                  fb = b.studentname.toLowerCase();
                if (fa < fb) {
                  return -1;
                }
                if (fa > fb) {
                  return 1;
                }
                return 0;
              }).map((stu) => (
                <NavDropdown.Item
                  key={stu.studentid}
                  onClick={e => {
                    getReports(e, stu.studentid)
                  }}
                >
                  {stu.studentname}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {selectedSubject && selectedSubject.contentarea ? (
        <ReportsBody data={selectedSubject} header={header} guideLink={guideLink} staticText={appData.staticText} />
      ) : null}
      <div className="app-footer">
        <Footer />
      </div>
    </div>
  );
};

export default ReportsParent;